(() => {
    window.mapLoaded = false

    const isInViewport = (el, percentVisible) => {
        if (!el) return false

        let rect = el.getBoundingClientRect(),
            windowHeight = (window.innerHeight || document.documentElement.clientHeight)

        return !(
            Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < percentVisible ||
            Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
        )
    };

    const loadMap = () => {
        if (window.mapLoaded || !isInViewport(document.querySelector('[data-map-holder]'), 4))
            return

        window.mapLoaded = true

        const gMapsJs = document.createElement('script')
        gMapsJs.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA_IckNle5OAQHtUqaSKFQHDtGe9nmJj_0&callback=initMap&libraries=&v=weekly`

        document.body.insertAdjacentElement('beforeend', gMapsJs)
    }

    document.addEventListener("DOMContentLoaded", loadMap);
    document.addEventListener("scroll", loadMap);
    window.loadMap = loadMap
})();

const initMap = () => {
    const mapEl = document.querySelector('[data-map-holder]') as HTMLElement
    if (!mapEl) return

    const officeCoordinates = window.wpObject.mapPosition.replace(' ', '').split(','),
        officeAddress = {lat: parseFloat(officeCoordinates[0] ?? '0'), lng: parseFloat(officeCoordinates[1] ?? '0')}

    const mapOffice = new google.maps.Map(mapEl, {
        center: officeAddress,
        zoom: 15,
        disableDefaultUI: true,
        styles: [
            {
                "featureType": "all",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#001626"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#212121"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#28597b"
                    }
                ]
            },
            {
                "featureType": "administrative.country",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "administrative.locality",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#2d6994"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000e18"
                    },
                    {
                        "visibility": "simplified"
                    },
                    {
                        "gamma": "1"
                    },
                    {
                        "weight": "10.00"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#225579"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#1b1b1b"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#2c2c2c"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#6fa0c4"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#00243f"
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.highway.controlled_access",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#00243f"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#00243f"
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "simplified"
                    },
                    {
                        "color": "#002138"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#2d6994"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#2d6994"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000e18"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#0e334d"
                    }
                ]
            }
        ]
    });

    const marker = new google.maps.Marker({
        position: officeAddress,
        icon: window.wpObject.mapIcon,
        map: mapOffice
    });

    mapOffice.addListener("tilesloaded", () => mapEl.classList.remove('is-loading'))
}

declare global {
    interface Window {
        loadMap: () => void
        initMap: () => void
        mapLoaded: boolean
        wpObject: {
            gMapsApi: string
            mapPosition: string
            mapAddressUrl: string
            mapIcon: string
            mapBackgroundColor: string
            mapPrimaryColor: string
        }
    }
}

window.initMap = initMap